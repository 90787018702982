import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { useSlug, useTranslationLink } from 'src/utils';
import { SEO } from 'src/components/common';
import { Content } from 'src/components/product';
import { GetBirthQuery } from 'graphql-types';
import { ProductPageContextProps, LangKeyType } from 'custom-types';

type Props = PageProps<GetBirthQuery, ProductPageContextProps>;

export const query = graphql`
  query GetBirth(
    $lang: String!
    $productUid: String!
    $brandUid: String!
    $seasonId: String!
  ) {
    site {
      siteMetadata {
        titleCustom {
          ja {
            birth
          }
          en {
            birth
          }
        }
        description {
          ja {
            birth
          }
          en {
            birth
          }
        }
      }
    }
    prismic {
      birth(lang: $lang, uid: $productUid) {
        _meta {
          alternateLanguages {
            uid
            type
          }
        }
        title
        meta_title
        meta_description
        content
        images {
          image
          imageSharp {
            publicURL
          }
        }
      }
      ...ProductBirthContent
    }
  }
`;

type UseSEOProps = {
  site: GetBirthQuery['site'];
  birth: GetBirthQuery['prismic']['birth'];
  isSingle: boolean;
  langKey: LangKeyType;
};
function useSEO({ site, birth, isSingle, langKey }: UseSEOProps) {
  return React.useMemo(() => {
    let title: string | null;
    if (isSingle) {
      title = birth?.meta_title ? RichText.asText(birth?.meta_title) : null;
    } else {
      if (langKey === 'ja') {
        title = site?.siteMetadata?.titleCustom?.ja?.birth || null;
      } else {
        title = site?.siteMetadata?.titleCustom?.en?.birth || null;
      }
    }
    let description: string | null;
    if (isSingle) {
      description = birth?.meta_description
        ? RichText.asText(birth?.meta_description)
        : null;
    } else {
      if (langKey === 'ja') {
        description = site?.siteMetadata?.description?.ja?.birth || null;
      } else {
        description = site?.siteMetadata?.description?.en?.birth || null;
      }
    }
    const image = birth?.images?.[0].imageSharp?.publicURL || undefined;
    return {
      title,
      description,
      image,
      type: isSingle ? ('product' as const) : ('birth' as const),
    };
  }, [site, birth, isSingle, langKey]);
}

const Birth: React.FC<Props> = ({
  data: { site, prismic },
  pageContext: { isSingle },
}: Props) => {
  const { slug, langKey } = useSlug();
  const seo = useSEO({
    site,
    birth: prismic?.birth,
    isSingle,
    langKey,
  });

  useTranslationLink({
    slug,
    langKey,
    uid: isSingle
      ? prismic.birth?._meta?.alternateLanguages?.[0]?.uid
      : 'birth',
    type: isSingle
      ? prismic.birth?._meta?.alternateLanguages?.[0]?.type
      : 'brand-archive',
  });

  return (
    <>
      <SEO {...seo} />
      <Content {...prismic} isSingle={isSingle} />
    </>
  );
};

export default Birth;
